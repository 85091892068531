.sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;}
  .sidebar-menu > li {
  position: relative;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #421826b3;
}
.new-alert-badge.hot {
    color: var(--light);
    opacity: 1;
}
.sidebar-menu > li:last-child {
  border-bottom: 1px solid transparent;

}
.treeview-menu.second-child {
  background: #1c2737 !important;
}
    .sidebar-menu > li > a {
  padding: 5px 5px 5px 15px;
  display: block;
}
.sidebar-menu > li:hover > a, .sidebar-menu > li.active > a {
  color: #fff;
}

.sidebar-menu > li.active > a {
  background: #262e4a  !important;
  opacity:1;
}


.sidebar-menu > li > .treeview-menu {
  margin: 0;
  background: #1f2b3d;
  opacity:0.7
}
    .sidebar-menu > li .label,
    .sidebar-menu > li .badge {
      margin-top: 3px;
      margin-right: 5px; }
  .sidebar-menu li.header {
    padding: 10px 25px 10px 15px;
    font-size: 12px;}
  .sidebar-menu li > a > .fa-angle-left {
    width: auto;
    height: auto;
    padding: 0;
    margin-right: 10px;
    margin-top: 3px; }
  .sidebar-menu li.active > a > .fa-angle-left {
    transform: rotate(-90deg); }

    .treeview a:focus {
  text-decoration: none;
  background-color: inherit !important;
}
.sidebar-menu li.active > .treeview-menu {
  display: block;
  border-top: 1px solid #c2bbbb;
}
  .sidebar-menu a {
    text-decoration: none; }
    .treeview .side-icon{border: none;width: 16px;height: auto;}
  .sidebar-menu .treeview-menu {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
     }
    .sidebar-menu .treeview-menu > li {
      margin: 0; }
.sidebar-menu .treeview-menu > li > a {
  padding: 5px 5px 5px 15px;
  display: block;
  font-size: 12px;
  border-bottom: 1px solid #28372f;
}
        .sidebar-menu .treeview-menu > li > a > .fa {
          width: 20px; }
        .sidebar-menu .treeview-menu > li > a > .fa-angle-left,
        .sidebar-menu .treeview-menu > li > a > .fa-angle-down {
          width: auto; }
.sidebar-menu .treeview-menu > li > a:hover {
    background:#30435f !important;
    opacity:1;

}
.sidebar-menu .treeview-menu > li.active > a {
    font-weight: bold;
    font-size: 15px;
}
.sidebar-menu .treeview-menu > li.selected > a{
   background:#cc5500 !important;
   color: #000;
   opacity:1;
}


.std-block-head {
  background: #cc5500;
  color: #ffffff;
  font-size: 15px;
  padding-top: 7px;
  padding-bottom: 4px;
  margin-bottom: 0px;
  font-weight: 900 !important;
}
