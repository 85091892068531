:root {
    --betnare-primary: rgba(22, 32, 44, 0.75);
    --betnare-secondary: #2c3c44;
    --betnare-footer:#16202C;
    --footer-color:#fff;
    --betnare-button-login: #FFC107;
    --betnare-button-register: #ffffff;
    --banner-background: url(../../assets/img/auth/img-17.webp)!important;
    --betnare-header-bg:#16202c;
    --betnare-body-bg:#16202c;
    --hot:#f66203;
    --black:#000;
    --bs-gutter-x: 20px;
    --bs-cyan:#3f9ad1;
    
    --faded-color:rgb(105, 129, 154);
    --link-color:#3f9ad1;
    --text-value:#e95e0b;
    --odds-button: #23313d;
    --kiron-odds-button: #2D4352;
    --bet-history:#1e2b36;
    --icon-size:13px;
    --validation-fail-color:#C7DE6D;
    --font-size-large:14px;
    --font-size-large1:16px;
    --fon-size-large-variation1:13px;
    --fon-size-large-variation2:15px;
    --fon-size-large-variation2:16px;
    --font-size-small:11px;
    --font-size-small-variation1:12px;
    --font-size-small-variation2:10px;
    --font-size-small-variation3:9px;
    --color-link-sports:#FFC428 ;
    --light2:#a7d1f6;

    --betnare-live:red;
    --slip-separator-color:rgb(51, 76, 92);
    --color-site: #ce3d10;
    --betnare-side-bg:#121a23;
    --betslip-header:#4c372a;
    --betslip-mybets:#cd28ec;
    --mybets-slip:#1e2d3b;
    --button-radius:3px;
    --betnare-odds-body:#2d3641;
    --betnare-input:#28374e;
    --betnare-input-border:#2d3641;
    --betnare-shadow-borders:#2c3c44;
    --betnare-font-size-nav:14px;
    --betnare-font-size-small:12px;
    --font-size-side:1.5rem;
    --font-size-side-sm:1.3rem;
    --body-line-weight:2;
    --body-line-weight-sm:1.5;
    --width-side:18.75rem;
    --width-side-sm:100%;
    --button-color:#334c5c;
    --testimonial-top-md:9.3rem;
    --font-size-bet-body:1.4rem;
    --font-size-bet-body-sm:1.3rem;
    --light: #fff;
    --light-inactive: #585353FF;
    --gold:gold;
    --orange:orange;
    --dark:#000;
    --grey:#adadad;
    --aqua:#adadad;
    --tab-color:#223542;
    --nav-top-color:#f0f0f0;
    --aqua-text:aqua;
    --footer-links:whitesmoke;
    --jackpot-header:#16202C;
    --white:#fff;
    --testimonial:#253743;
    --white-darken:#16202C;
    --more-markets-text:rgb(255, 193, 7);
    --slip-height:83vh;
    --light-gray:#23313d;
    --font-small:11px;
    --red:red;
    --suspended:#3b3b2e;
    --suspended2:#fed80026;
    --suspende2:#1a1a1;    
    --close-color:#bb2f2f;
    --tax-info-color:#313c48;
    --share-top:5.5rem;
    --height-slip-kiron:19rem;
    --slip-bottom-space:24rem;
    --slip-bottom:20.5rem;
    --green:#0f5132;
    --bg-loader:#8b8888;
    --font-weight1:800;
    --font-weight2:700;
    --font-weight3:600;
    --font-weight4:500;
    --font-weight5:400;
    --font-weight6:300;
    --nav-color:#192433;
    --transparent:transparent;
    --app-download-link-color:#1e354e;
    --odd-change-position:7rem;
    --slip-tab:5.5rem;
    --kiron-competition:#34495e;
    --top-header-nav:#16202C;
    --top-nav-items:#f0f0f0;
    --side-stats:aqua;
    --betslip-list:rgb(31 54 69);
    --toggle-btn:transparent;
    --slip-message-alert:9.5rem;
    --alert-slip-color:#c9e0ee;
    --transparent:transparent;
    --lite-top-color:#0DCAF0;
    --vaix-lite-top-color:#23313d;
    --picked:#fdd835;
    --orange:orange;
    --secondary:#fdd835;
    --tabs-home:#000;
    --betnare-mobile-nav:#192533;
    --font-tiny:8px;
    --font-weight-normal:normal;
    --always-white:#fff;
    --viax-betslip-bg:#141c25;
    --box-shadow-sports: 0 0.5rem 0.2rem rgba(0,0,0,.175);
}
.shadow-sports-header{
    /*box-shadow:var(--box-shadow-sports) !important;*/
}

.light-theme {
    /* light mode variables go here */
    --tabs-home:#ffffff;
    --testimonial:#ffffff;
    --betnare-primary:#ffffff;
    --top-header-nav:#ffffff;
    --top-nav-items:#05070a;
    --footer-color:#05070a;
    --footer-links:#05070a;
    --betnare-secondary: #fff;
    --betnare-button-login:  #FFC107;
    --betnare-footer:#adadad;
    --mybets-slip:#ffffff;
    --odds-button: #e8e8e8;
    --jackpot-header:#ffffff;
    --betnare-button-register: #ffffff;
    --banner-background: url(../../assets/img/auth/img-17.webp)!important;
    --betslip-header:#4c372a;
    --betnare-side-bg:#fff;
    --betnare-header-bg:#ffffff;
    --viax-betslip-bg:#fff;
    --betslip-mybets:#fff;
    --button-radius:3px;
    --betnare-odds-body:#fff;
    --betnare-input:#e8e8e8;
    --betnare-input-border:#e8e8e8;
    --betnare-shadow-borders:#fff;
    --betnare-font-size-nav:14px;
    --betnare-font-size-small:12px;
    --font-size-side:1.8rem;
    --font-size-side-sm:1.3rem;
    --body-line-weight:2;
    --body-line-weight-sm:1;
    --width-side:18.75rem;
    --width-side-sm:14.813rem;
    --button-color:#fff;
    --testimonial-top-md:10.5rem;
    --font-size-bet-body:1.4rem;
    --font-size-bet-body-sm:1.3rem;
    --light: #000;
    --grey:#000;
    --aqua:#adadad;
    --tab-color: #adadad;
    --cancel:#030928;
    --betnare-light-blue:#4f768e;
    --light-blue-variant:#334c5c;
    --secondary:#fdd835;
    --variant2:#1f2f38;
    --nav-top-color:#05070a;
    --aqua-text:#05070a;
    --white-darken:#fafafa;
    --kiron-competition:#fafafa;
    --toggle-btn:#000;
    --picked:#fdd835;
    --betnare-body-bg:#fafafa;
}
